.container {
    padding: 2%;
    background: rgba(250, 250, 250, 0.88);
    margin: 0 auto;
    display: block;
    float: none;
    border-radius: 0 5px 5px;
}

.datepicker {
    height: 48px;
    cursor: pointer;
    padding: 5px 15px;
    border: 0;
    border-radius: unset;
    background-color: #3eaae8;
    font: inherit;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    width: 100%;
}

.labelInfo {
    display: block;
    color: gray;
    font-size: 11px;
}

.controlLabel {
    color: #3d3d3d;
}

.inline {
    display: inline-block;
    margin-right: 20px;
}

.row {
    margin: 0;
}

.addon {
    color: white;
    background: #3d3d3d;
    border-radius: unset;
}

.search {
    background: #4a9033;
    color: #fff;
    border: none;
    display: inline-block;
    margin-top: 5px;
    font-weight: bold;
    font-size: 18px;
    min-width: 180px;
    width: 100%;
    height: 48px;
}

.search:hover {
    opacity: 0.7;
}

.search:disabled {
    opacity: 0.7;
    cursor: no-drop;
}

/**/
