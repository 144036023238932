.blackBoxWrapper {
  background: #000;
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  padding: 25px 0;
}

.blackBoxWrapper h1 {
  margin-top: 0;
  color: #fff;
  text-align: center;
}
