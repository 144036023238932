.carouselCaptionLeft {
  width: 300px;
  margin-left: auto;
  margin-right: 41%;
  margin-bottom: 5px;
  background: none;
  max-width: 500px;
  left: 77px;
}

.carouname:hover {
  color: #428bca;
  cursor: pointer;
}

.carouname {
  font-size: 25px;
  color: #515151;
  font-weight: bold;
  text-transform: uppercase;
  width: 68%;
  height: auto;
  float: left;
  line-height: 25px;
  text-align: left;
  padding-top: 10px;
}

.carouselCaptionLeft .carouname {
  width: 300px;
  background-color: #fff;
  background: #fff;
  background: rgba(255, 255, 255, 0.75);
  padding: 3px 10px;
  text-transform: none;
  float: left;
  border-radius: 4px;
  text-shadow: none;
  line-height: 20px;
}

.carousubname {
  font-size: 14px;
  text-transform: none;
}
.carouselCaptionLeft .carousubname {
  font-size: 11px;
}

.clearboth {
  clear: both;
  float: none;
}

.carousubnameImg {
  float: left;
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.carousubname1 {
  float: left;
  padding-bottom: 6px;
}

.carouprice {
  font-size: 25px;
  border-top: 3px solid #515151;
  border-bottom: 3px solid #515151;
  color: #515151;
  font-weight: bold;
  text-transform: uppercase;
  width: 32%;
  height: auto;
  float: right;
  margin-top: 10px;
}

.carouprice:hover {
  color: #e63451;
}
